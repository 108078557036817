(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define([], factory);
	else if(typeof exports === 'object')
		exports["@alt1/base"] = factory();
	else
		root["A1lib"] = factory();
})((typeof self!='undefined'?self:this), () => {
return 